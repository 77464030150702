@font-face {
  font-family: 'robotobold';
  src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
  url('../fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'robotoregular';
  src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
  url('../fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'robotomedium';
  src: url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
  url('../fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}
