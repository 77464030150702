.student-codes {
    margin: 0;
    padding: 0;

    + .student-codes {
        page-break-before: always;
    }
}

.student-code {
    border-color: inherit;
    border-style: dashed;
    border-width: 1px;
    box-sizing: border-box;
    display: inline-block;
    margin: 5% 10px;
    padding: 1em 2em;
    text-align: center;
    flex-basis: 30%;

    /*&:nth-child(3n),
    &:nth-child(3n - 1) {
        margin-left: 5%;
    }*/
}
